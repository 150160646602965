<template>
  <div>
    <figure>
      <img
        class="image__item"
        :src="source"
        loading="lazy"
        :style="{ 'aspect-ratio': getAspect() }"
        alt="Description"
      />
    </figure>
  </div>
</template>

<script>
import LazyLoad from "../../directives/lazyLoad.js";

export default {
  name: "imageItem",
  props: ['source', 'cardType'],
  data() {
    return {
      posterChange: true,
    };
  },
  watch: {
    source(val) {
      if (val) {
        this.posterChange = !this.posterChange;
      }
    },
  },
  methods: {
    getAspect() {
      if (this.cardType && this.cardType.type === 'PORTRAIT') {
        return '2.8 / 4'; // Correct format for aspect-ratio
      } else {
        return '16 / 9'; // Correct format for aspect-ratio
      }
    }
  },
  directives: {
    lazyload: LazyLoad,
  },
};
</script>

<style lang="scss" scoped>
@import "./imageItem.scss";

/* No need for this if you're setting aspect-ratio inline */
.image__item {
  max-width: 100%; /* Ensure image scales within its container */
  height: auto; /* Maintains aspect ratio */
}
</style>
